import * as types from "../mutation-types";

// import axios from "axios";
import { axios, removeHeader, setHeader } from "../../utils/axios";

import Vue from "vue";

const state = () => ({
  is_authenticated: false,
  token: "",
  refreshToken: "",
  user: null,

  loading: false
});

const getters = {
  domen: ({ domen }) => domen,
  is_authenticated: ({ is_authenticated }) => is_authenticated,
  token: ({ token }) => token,
  refreshToken: ({ refreshToken }) => refreshToken,
  user: ({ user }) => user,
  loading: ({ loading }) => loading
};

const actions = {
  login(
    { commit, rootGetters, dispatch },
    { data, cb = () => {}, cbError = () => {} }
  ) {
    const url = `login`;

    // Start loading button in login form
    commit(types.SAVE_LOADING_STATE, true);

    axios
      .post(url, {
        password: data.password,
        email: data.email
      })
      .then(response => {
        console.log(response.data);

        // // Save token
        commit(types.SAVE_USER_TOKEN, response.data.token);

        setHeader(response.data.token);

        dispatch("getUser");

        cb();

        commit(types.SAVE_LOADING_STATE, false);
      })
      .catch(error => {
        if (error.response.status === 401) {
          cbError("User not found");
        } else {
          cbError("Something went wrong.Try again!");
        }

        // Stoping loading button in registration form
        commit(types.SAVE_LOADING_STATE, false);
      });
  },

  getUser({ commit, rootGetters, dispatch }) {
    const url = `get/user`;

    console.log("getUsergetUsergetUser");

    axios
      .get(url)
      .then(response => {
        console.log("getUser", response.data);

        commit(types.SAVE_USER_INFO, response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  checkToken({ commit, rootGetters, dispatch }, { cb = () => {} }) {
    const url = `/check/token`;

    // let token = Vue.$cookies.get("user-token");

    // setHeader(response.data.token);

    console.log("token", token);

    console.log(url);
    console.log("checkTokencheckTokencheckToken");

    axios
      .post(url, {
        token: token
      })
      .then(response => {
        commit(types.SAVE_USER_TOKEN, token);

        setHeader(token);

        if (Vue.prototype.router.history.current.name == "Login")
          Vue.prototype.router.push("/");

        cb();
      })
      .catch(error => {
        console.log(error);

        Vue.prototype.router.push({ name: "Login" });

        cb();
      });
  },

  logout({ commit, state, dispatch }) {
    commit(types.DELETE_USER_INFO);

    removeHeader();

    Vue.prototype.router.push({ name: "Login" });
  }
};

const mutations = {
  [types.SAVE_LOADING_STATE](state, loading) {
    state.loading = loading;
  },

  [types.SAVE_USER_TOKEN](state, token) {
    Vue.$cookies.set("user-token", token);

    state.token = token;
  },

  [types.SAVE_USER_REFRESH_TOKEN](state, token) {
    Vue.$cookies.set("user-refresh-token", token, "1d");

    state.refreshToken = token;
  },

  [types.SAVE_USER_INFO](state, data) {
    state.user = data;
    state.is_authenticated = true;
  },

  [types.DELETE_USER_INFO](state) {
    Vue.$cookies.remove("user-token");
    Vue.$cookies.remove("user-refresh-token");

    state.user = null;
    state.token = null;
    state.refreshToken = null;
    state.is_authenticated = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
