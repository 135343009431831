export default {
  data: () => ({
    localValue: ""
  }),

  props: {
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: ""
    }
  },

  watch: {
    localValue(next, prev) {
      if (next != this.value) this.$emit("input", next);
    },

    value(next, prev) {
      if (this.localValue != next) this.localValue = next;
    }
  },

  mounted() {
    if (!this.localValue) this.localValue = this.value;

    if (this.value !== null && this.localValue !== this.value)
      this.localValue = this.value;

    if (!this.value && this.localValue) this.$emit("input", this.localValue);
  }
};
