import { domen } from "../../utils/constants";

const state = () => ({
  domen: domen
});

const getters = {
  domen: ({ domen }) => domen
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
