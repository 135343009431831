<template>
  <!-- <div class="c-dark-theme"> -->
  <div>
    <transition name="fade" mode="out-in">
      <router-view v-if="isShowContent" key="content"></router-view>

      <div class="app__spinner-container" v-if="!isShowContent" key="spinner">
        <CSpinner color="primary" style="width:4rem;height:4rem;" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { setHeader } from "@/utils/axios";

import Vue from "vue";

export default {
  name: "App",

  data: () => ({
    isShowContent: true
  }),

  created() {
    let token = Vue.$cookies.get("user-token");

    setHeader(token);

    this.getUser();
    // this.checkToken({
    //   cb: () => {
    //     this.isShowContent = true;
    //   }
    // });
  },

  methods: {
    ...mapActions({
      checkToken: "auth/checkToken",
      getUser: "auth/getUser"
    })
  }
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";

/* Import Flag Icons Set */
@import "~flag-icon-css/css/flag-icon.min.css";

// CoreUI Icons Set
// @import "~@coreui/icons/css/coreui-icons.min.css";

// body {
//   font-size: 16px;
// }

p {
  margin: 0;
}

.app {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }

  &__spinner-container {
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
