// Auth
export const SAVE_LOADING_STATE = "SAVE_LOADING_STATE";
export const SAVE_USER_TOKEN = "SAVE_USER_TOKEN";
export const SAVE_USER_REFRESH_TOKEN = "SAVE_USER_REFRESH_TOKEN";
export const SAVE_USER_INFO = "SAVE_USER_INFO";

export const DELETE_USER_INFO = "DELETE_USER_INFO";

//User
export const SAVE_USERS_DATA_STATE = "SAVE_USERS_DATA_STATE";

// Orders
export const SAVE_ORDERS_DATA_STATE = "SAVE_ORDERS_DATA_STATE";

// Transactions
export const SAVE_TRANSACTIONS_DATA_STATE = "SAVE_TRANSACTIONS_DATA_STATE";

// Operators
export const SAVE_OPERATORS_DATA_STATE = "SAVE_OPERATORS_DATA_STATE";

// Numbers
export const SAVE_NUMBERS_DATA_STATE = "SAVE_NUMBERS_DATA_STATE";

// Techinfo
export const SAVE_TECHINFO_DATA_STATE = "SAVE_TECHINFO_DATA_STATE";

// Countries
export const SAVE_COUNTRIES_DATA_STATE = "SAVE_COUNTRIES_DATA_STATE";

// Services
export const SAVE_SERVICES_DATA_STATE = "SAVE_SERVICES_DATA_STATE";

// Prices
export const SAVE_PRICES_DATA_STATE = "SAVE_PRICES_DATA_STATE";

// Goips
export const SAVE_GOIPS_DATA_STATE = "SAVE_GOIPS_DATA_STATE";

//Photo
export const SAVE_PHOTO_LINK = "SAVE_PHOTO_LINK";
export const SAVE_PHOTO_NAME = "SAVE_PHOTO_NAME";
export const SAVE_PHOTO_IS_SHOW = "SAVE_PHOTO_IS_SHOW";
export const SAVE_PHOTO_FILE = "SAVE_PHOTO_FILE";
export const SAVE_PHOTO_INDEX = "SAVE_PHOTO_INDEX";
export const SAVE_PHOTO_FIRST_LOAD = "SAVE_PHOTO_FIRST_LOAD";
