import Router from "vue-router";
import Vue from "vue";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Presetio
const Presetio = () => import("@/views/presetio/Presetio");

// Filteroom
const Filteroom = () => import("@/views/filteroom/Filteroom");

// Presetium
const Presetium = () => import("@/views/presetium/Presetium");

// Filteresco
const Filteresco = () => import("@/views/filteresco/Filteresco");

// Powerpoint
const PowerpointTag = () => import("@/views/powerpoint/PowerpointTag");
const PowerpointCollection = () =>
  import("@/views/powerpoint/PowerpointCollection");
const PowerpointAITag = () => import("@/views/powerpoint/PowerpointAITag");
const PowerpointAIPrompt = () => import("@/views/powerpoint/PowerpointAIPrompt");

// Instapano
const InstapanoTag = () => import("@/views/instapano/InstapanoTag");
const InstapanoCollection = () =>
  import("@/views/instapano/InstapanoCollection");
const InstapanoColor = () => import("@/views/instapano/InstapanoColor");
const InstapanoTexture = () => import("@/views/instapano/InstapanoTexture");
const InstapanoElement = () => import("@/views/instapano/InstapanoElement");

// Creator story
const CreatorStoryTag = () => import("@/views/creator-story/CreatorStoryTag");
const CreatorStoryColor = () =>
  import("@/views/creator-story/CreatorStoryColor");
const CreatorStoryTexture = () =>
  import("@/views/creator-story/CreatorStoryTexture");
const CreatorStoryElement = () =>
  import("@/views/creator-story/CreatorStoryElement");
const CreatorStoryCollection = () =>
  import("@/views/creator-story/CreatorStoryCollection");

// Iconios
const IconiosTag = () => import("@/views/iconios/IconiosTag");
const IconiosCollection = () => import("@/views/iconios/IconiosCollection");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      // redirect: "/",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "luna",
          meta: { label: "Luna" },
          component: Presetio
        },

        {
          path: "filteroom",
          meta: { label: "Filteroom" },
          component: Filteroom
        },

        {
          path: "presetium",
          meta: { label: "Presetium" },
          component: Presetium
        },

        {
          path: "filteresco",
          meta: { label: "Filteresco" },
          component: Filteresco
        },

        {
          path: "powerpoint/tag",
          meta: { label: "Powerpoint Tag" },
          component: PowerpointTag
        },
        {
          path: "powerpoint/collection",
          meta: { label: "Powerpoint Collection" },
          component: PowerpointCollection
        },
        {
          path: "powerpoint/ai-tag",
          meta: { label: "Powerpoint AI Tag" },
          component: PowerpointAITag
        },
        {
          path: "powerpoint/ai-prompt",
          meta: { label: "Powerpoint AI Prompt" },
          component: PowerpointAIPrompt
        },

        {
          path: "instapano/tag",
          meta: { label: "Instapano Tag" },
          component: InstapanoTag
        },
        {
          path: "instapano/collection",
          meta: { label: "Instapano Collection" },
          component: InstapanoCollection
        },
        {
          path: "instapano/color",
          meta: { label: "Instapano Collection" },
          component: InstapanoColor
        },
        {
          path: "instapano/texture",
          meta: { label: "Instapano Collection" },
          component: InstapanoTexture
        },
        {
          path: "instapano/element",
          meta: { label: "Instapano Collection" },
          component: InstapanoElement
        },

        {
          path: "creator-story/tag",
          meta: { label: "Instapano Tag" },
          component: CreatorStoryTag
        },
        {
          path: "creator-story/collection",
          meta: { label: "Creator Story Collection" },
          component: CreatorStoryCollection
        },
        {
          path: "creator-story/color",
          meta: { label: "Creator Story Collection" },
          component: CreatorStoryColor
        },
        {
          path: "creator-story/texture",
          meta: { label: "Creator Story Collection" },
          component: CreatorStoryTexture
        },
        {
          path: "creator-story/element",
          meta: { label: "Creator Story Collection" },
          component: CreatorStoryElement
        },

        {
          path: "iconios/tag",
          meta: { label: "Iconios Tag" },
          component: IconiosTag
        },
        {
          path: "iconios/collection",
          meta: { label: "Iconios Collection" },
          component: IconiosCollection
        }
      ]
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404
        },
        {
          path: "500",
          name: "Page500",
          component: Page500
        },
        {
          path: "login",
          name: "Login",
          component: Login
        }
        // {
        //   path: "register",
        //   name: "Register",
        //   component: Register
        // }
      ]
    }
  ]
});
